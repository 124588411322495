import React, { useState, useEffect, useRef } from 'react';
import { Router, Route, Switch, useHistory } from 'react-router-dom';
import useStateCallback from './utils/useStateCallback.js';
import Sign_up from "./Sign_up/";
import ResetPassword from "./ResetPassword/";
import { useAlert } from 'react-alert'
import * as Sentry from "@sentry/react";
import general_fetch from './utils/fetch';
import './App.css';
import Portals from './Portals/index.js';
import { all_legal_routes } from './config.js';

const App = () => {
    const alert = useAlert();
    const initial_load = useRef(false)
    const [login_modal_type, set_login_modal_type] = useState('normal') //todo
    const [user_details, set_user_details] = useState('')

    const history = useHistory();

    const [org_key_logo, set_org_key_logo] = useState("");
    const [org_key_id, set_org_key_id] = useState("");
    const [org_key_name, set_org_key_name] = useState("");
    const [org_key_state, set_org_key_state] = useState('');

    const [downtime_var, set_downtime_var] = useState(false);
    global.set_downtime_var = set_downtime_var

    const [page_loader, set_page_loader] = useStateCallback({
        show: true,
        text: "Please wait while we set up everything..."
    });

    global.trigger_alert = (type, message) => {
        alert[type](message)
    }

    const get_org_key = () => {
        if (!window.location.origin.includes("infurnia.com")) {
            set_org_key_state(window.location.origin.split('.')[1])
            return window.location.origin.split('.')[1]
        } else {
            var initial_key = window.location.pathname.split('/')[1]
            if (all_legal_routes.includes(initial_key)) {
                return "";
            } else {
                set_org_key_state(initial_key)
                return initial_key;
            }
        }
    }

    useEffect(() => {
        (() => {
            var analytics = window.analytics = window.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
                analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"]; analytics.factory = function (e) { return function () { var t = Array.prototype.slice.call(arguments); t.unshift(e); analytics.push(t); return analytics } }; for (var e = 0; e < analytics.methods.length; e++) { var key = analytics.methods[e]; analytics[key] = analytics.factory(key) } analytics.load = function (key, e) { var t = document.createElement("script"); t.type = "text/javascript"; t.async = !0; t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js"; var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(t, n); analytics._loadOptions = e }; window['analytics']._writeKey = global.config.segment_id;; analytics.SNIPPET_VERSION = "4.15.3";
                analytics.load(global.config.segment_id);
            }
        })();
        (async () => {

            await set_org_details()

            initial_load.current = true;
            set_page_loader({ show: false });
        })()
    }, []);

    useEffect(() => {
        if (user_details) {
            Sentry.configureScope(function (scope) {
                scope.setUser({
                    id: user_details.id,
                    email: user_details.email
                });
            });
        }
    }, [user_details]);

    const set_org_details = async () => {
        try {
            var org_key = get_org_key()
            console.log('window.location.href', window.location.origin, window.location.href, window.location.origin.split('.'), window.location.href.split('/')[2].split('.')[1])
            if (org_key) {
                var resp_store_white_labelling = await general_fetch({ url: '/store/get_white_labelling_details', body: { white_labelling_url: org_key } })
                if (resp_store_white_labelling) {
                    set_org_key_id(resp_store_white_labelling.id);
                    set_org_key_logo(global.config.server_path + "/" + resp_store_white_labelling.logo_path);
                    set_org_key_name(resp_store_white_labelling.name);
                } else {
                    alert.error('Invalid Organization URL');
                    history.push('/')
                }
            } else {
                set_org_key_logo('/infurnia.png')
            }
        } catch (err) {
            console.error(err)
            history.push('/')
        }
    }

    useEffect(() => {
        if (!window.location.origin.includes('infurnia.com')) {
            document.title = 'Login - ' + org_key_name
        } else {
            document.title = 'Login - Infurnia'
        }
    }, [org_key_name]);

    useEffect(() => {
        if (!window.location.origin.includes('infurnia.com')) {
            var link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.head.appendChild(link);
            }
            console.log('link.href', global.config.server_path + '/' + org_key_logo)
            link.href = org_key_logo;
        }
    }, [org_key_logo]);


    return (
        <div className="App">
            {
                downtime_var ?
                    <div className='downtime'>
                        <div className='downtime_child'>
                            Our website is currently undergoing maintenance and will be back online at 3 AM (IST). We apologize for any inconvenience caused. Thank you for your patience.
                        </div>
                    </div> : ''
            }
            {
                page_loader && page_loader.show ? (
                    <div style={{ position: 'fixed', backgroundColor: 'rgba(20,20,20,0.7', left: 0, top: 0, width: '100%', height: '100%', zIndex: 10000 }}>
                        <div style={{ position: 'absolute', width: '350px', height: '40px', left: 'calc(50% - 175px)', top: 'calc(50% - 20px)', backgroundColor: 'white', color: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '2px' }}>
                            <i className="fa fa-spinner fa-spin"></i> &nbsp; &nbsp;
                            <div>{page_loader.text}</div>
                        </div>
                    </div>
                ) : ''
            }

            {
                initial_load.current ?
                    <div style={{height: '100dvh', width: '100dvw'}}>
                        <Router history={history}>
                            <Switch>
                                <Route exact path={["/:org_key/reset_password/:reset_password_token", "/reset_password/:reset_password_token"]} render={({ match }) => <ResetPassword org_key={match.params.org_key} set_page_loader={set_page_loader} reset_password_token={match.params.reset_password_token} org_key_id={org_key_id} org_key_logo={org_key_logo} org_key_name={org_key_name} />} />
                                <Route exact path={["/:org_key?/portals", "/portals"]} render={({ match }) => <Portals org_key={match.params.org_key} user_details={user_details} set_user_details={set_user_details} org_key_id={org_key_id} org_key_logo={org_key_logo} org_key_name={org_key_name}/>} />
                                <Route exact path={["/:org_key?", ""]} render={({ match }) => (<Sign_up org_key={org_key_state} user_details={user_details} set_user_details={set_user_details} set_page_loader={set_page_loader} org_key_id={org_key_id} org_key_logo={org_key_logo} org_key_name={org_key_name} login_modal_type={login_modal_type} set_login_modal_type={set_login_modal_type} />)} />
                                <Route render={() => (<div>Not Found</div>)} />
                            </Switch>
                        </Router>
                    </div>
                    :
                    <img alt='Not Available' src="/resources/images/site_image.jpeg" style={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100%' }} />
            }
        </div>
    )
}

export default App;