export const all_legal_routes = ["", "login", "reset_password", "portals"];

var config_settings = {
	"production":{
		server_path: 'https://productionbackend.infurnia.com',
		render_server: 'http://localhost:6165/',
		admin_path: 'https://admin.infurnia.com/',
		bdp_path: 'https://bdp.infurnia.com/',
		mes_path: 'https://mes.infurnia.com/',
		sales_path: 'https://csp.infurnia.com/',
		auth_redirect_path:'https://login.infurnia.com/',
		application_env: 'production',
		affilate_path: 'https://affiliate.infurnia.com',
		design_path:'https://design.infurnia.com',
		sentry_environment: 'production',
		segment_id: 'gbJtAn1ELm1Ur2drGQJte06NbRz6MvV8'
	},
	"hotfix":{
		server_path: 'https://hotfixbackend.infurnia.com',
		render_server: 'http://localhost:6165/',
		admin_path: 'https://hotfixadmin.infurnia.com/',
		bdp_path: 'https://hotfixbdp.infurnia.com/',
		mes_path: 'https://hotfixmes.infurnia.com/',
		sales_path: 'https://hotfixcsp.infurnia.com/',
		auth_redirect_path:'https://hotfixlogin.infurnia.com/',
		application_env: 'hotfix',
		affilate_path: 'https://hotfixaffiliate.infurnia.com',
		design_path:'https://hotfixdesign.infurnia.com',
		sentry_environment: 'hotfix',
	},
	"preprod":{
		server_path: 'https://preprodbackend.infurnia.com',
		render_server: 'http://localhost:6165/',
		admin_path: 'https://preprodadmin.infurnia.com/',
		bdp_path: 'https://preprodbdp.infurnia.com/',
		mes_path: 'https://preprodmes.infurnia.com/',
		sales_path: 'https://preprodcsp.infurnia.com/',
		auth_redirect_path:'https://preprodlogin.infurnia.com/',
		application_env: 'preprod',
		affilate_path: 'https://preprodaffiliate.infurnia.com',
		design_path:'https://preproddesign.infurnia.com',
		sentry_environment: 'preprod',
	},
	"staging":{
		server_path: 'https://stagebackend.infurnia.com',
		render_server: 'http://localhost:6165/',
		admin_path: 'https://stageadmin.infurnia.com/',
		bdp_path: 'https://stagebdp.infurnia.com/',
		mes_path: 'https://stagemes.infurnia.com/',
		sales_path: 'https://stagecsp.infurnia.com/',
		auth_redirect_path:'https://stagelogin.infurnia.com/',
		application_env: 'stage',
		affilate_path: 'https://stagingaffiliate.infurnia.com',
		design_path:'https://stagedesign.infurnia.com',
		sentry_environment: 'stage',
		segment_id:'QqD5UcnfjpAHaMdppBI17SsXQqxQYym6'
	},
	"dev":{
		server_path: 'https://stagebackend.infurnia.com',
		render_server: 'http://localhost:6165/',
		admin_path: 'https://stageadmin.infurnia.com/',
		bdp_path: 'https://stagebdp.infurnia.com/',
		mes_path: 'https://stagemes.infurnia.com/',
		sales_path: 'https://stagecsp.infurnia.com/',
		auth_redirect_path:'https://stagelogin.infurnia.com/',
		application_env: 'dev',
		affilate_path: 'https://stagingaffiliate.infurnia.com',
		design_path:'https://localdesign.infurnia.com',
		sentry_environment: 'dev',
		segment_id:'qhvvFR3wugv24wCWjSJsBOIFcqpKaklw'
	}
}

var config = config_settings[process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'dev']
global.config = config
//https://stagingbackend.infurnia.com
// server_path: 'https://newdevbackend.infurnia.com/',
// 		render_server: 'http://localhost:6165/',
// 		admin_path: 'https://stagingadmin.infurnia.com/',
// 		auth_redirect_path:'http://localhost:3000',
// 		application_env: 'dev',
// 		affilate_path: 'https://stagingaffiliate.infurnia.com',
